<template>
<div class="main">
  <div class="home">
    <div class="content">
      <el-card  style="margin-bottom: 5px;">
        <div class="cardNumber">
          <div class="card-number">
            <div>活跃人数</div>
            <div class="card-num">{{ cardNum.activeCount }}</div>
          </div>
          <div class="card-number">
            <div>新增人数</div>
            <div class="card-num">{{ cardNum.allAddCount }}</div>
          </div>
          <div class="card-number">
            <div>实际新增</div>
            <div class="card-num">{{ cardNum.newAddCount }}</div>
          </div>
          <div class="card-number">
            <div>会员人数</div>
            <div class="card-num" @click="vipData" style=" cursor: pointer;">{{ cardNum.vipCount }}</div>
          </div>
        </div>
        
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="header-input">
          <div style="display:flex;align-items:center;">
            <el-input
              placeholder="搜索用户"
              v-model="url"
              prefix-icon="el-icon-search"
              clearable
              style="width: 300px;margin: 10px;">
            </el-input>
            <el-button style="margin-left: 5px;" class="allDown" type="primary" @click="toQuery()" @keydown.enter="toQuery()">查 询</el-button>
          </div>
          <!-- 搜索 -->
          <div style="display:flex;align-items:center;flex-wrap: wrap;">
            <el-input
              placeholder="访问量"
              v-model="views"
              prefix-icon="el-icon-user-solid"
              clearable
              style="width: 110px;margin-left: 10px;margin-bottom: 10px;">
            </el-input>
            <el-input
              placeholder="评级"
              v-model="rating"
              prefix-icon="el-icon-star-on"
              clearable
              style="width: 100px;margin-left: 10px;margin-bottom: 10px;">
            </el-input>
            <el-input
              placeholder="页码"
              v-model="page"
              prefix-icon="el-icon-d-caret"
              clearable
              style="width: 80px;margin-left: 10px;margin-bottom: 10px;">
            </el-input>
            <el-input
              placeholder="数量"
              v-model="limit"
              prefix-icon="el-icon-caret-right"
              clearable
              style="width: 80px;margin-left: 10px;margin-bottom: 10px;">
            </el-input>
            <el-date-picker
              v-model="create_start_time"
              type="date"
              placeholder="最小时间"
              style="width: 120px;margin-left: 10px;margin-bottom: 10px;">
            </el-date-picker>
            <el-date-picker
              v-model="create_end_time"
              type="date"
              placeholder="最大时间"
              style="width: 120px;margin-left: 10px;margin-bottom: 10px;">
            </el-date-picker>
          </div>
          <!-- 评级搜索 -->
          <div style="margin-top: 0px;display: flex;align-items:center;">
            <el-radio-group v-model="autoGetRating" size="medium">
              <!-- <el-radio-button label="全部"></el-radio-button> -->
              <el-radio-button label="-3"></el-radio-button>
              <el-radio-button label="-2"></el-radio-button>
              <el-radio-button label="-1"></el-radio-button>
              <el-radio-button label="0"></el-radio-button>
              <el-radio-button label="1"></el-radio-button>
              <el-radio-button label="2"></el-radio-button>
              <el-radio-button label="3"></el-radio-button>
            </el-radio-group>
            <!-- 一键查询待审批（自动选择最小时间今天，评级0，访问量大于100） -->
            <el-button style="margin-left: 10px;" type="warning" @click="ToAudit()" size="small">待审核</el-button>
          </div>
        </div>
        <!-- 批量级别按钮 -->
        <div slot="header" class="clearfix" v-if="clickCount>=5">
          <!-- <el-button class="allDown" type="danger" @click="allDown">一键降级</el-button>
          <el-button class="allUp" type="primary">一键升级</el-button> -->
          <el-input
            placeholder="dlink"
            clearable
            style="width:222px"
            v-model="dlink">
          </el-input>
          <el-button style="margin-left: 10px;" class="allDown" type="primary" @click="changeDlink()">确定</el-button>
          <p style="font-size:13px;color:#888;margin-left: 10px;">缓存：{{ cacheDlink }}</p>
        </div>
        <!-- 表格数据 -->
         <el-table
        :data="getList"
        stripe
        style="width: 100%"
        :default-sort = "{prop: 'date', order: 'descending'}">
        <el-table-column
          prop="rating"
          label="头像"
          key="ratingColumn"
          align="center"
          width="50"
          v-if="rating">
          <template slot-scope="scope" v-if="onlyBatchnum">
            <div @click="goLogin(scope.row)" style="cursor: pointer;">
              <el-avatar size="small" :src="scope.row.avatar?.replace(/oss.vlink.cc/, 'vcdn.vlink.cc')"></el-avatar>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          key="nameColumn"
          label="昵称"
          align="center"
          sortable
          width="130"
          v-if="rating">
        </el-table-column>
        <el-table-column
          prop="url"
          label="用户名"
          key="urlColumn"
          align="center">
          <template slot-scope="scope">
            <div @click="goLink(scope.row.url)" style="cursor: pointer;">
              {{ scope.row.url }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="rating"
          label="评级"
          align="center"
          width="140"
          v-if="rating">
          <template slot-scope="scope" v-if="onlyBatchnum">
            <el-input-number v-model="scope.row.rating" @change="oRating(scope.row)" :min="-3" :max="3" size="mini"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="linkList"
          label="链接"
          key="linkListColumn"
          align="center"
          width="90"
          sortable
          v-if="rating&&vipVp">
          <template slot-scope="scope">
            {{ scope.row.linkList ? scope.row.linkList.length : scope.row.linkListCount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="vipInfo"
          label="vip"
          key="vipInfoColumn"
          width="100"
          align="center"
          v-if="vipVp">
          <template slot-scope="scope">
            <!-- Check if vipInfo is null or an empty object -->
            <span v-if="!scope.row.vipInfo || Object.keys(scope.row.vipInfo).length === 0">———</span>
            <span v-else>会员
              <span :class="getVipDaysClass(scope.row.vipInfo.expire_time)">{{ vipDays(scope.row.vipInfo.expire_time) }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="views"
          label="PV"
          key="viewsColumn"
          align="center"
          sortable
          v-if="vipVp">
        </el-table-column>
        <el-table-column
          prop="sumClick"
          key="sumClickColumn"
          label="点击"
          align="center"
          sortable
          v-if="vipVp">
        </el-table-column>
        <el-table-column
          prop="updatedAt"
          key="updatedAtColumn"
          label="更新时间"
          align="center">
          <template slot-scope="scope">
            {{ timeAgo(scope.row.updatedAt) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          v-if="rating">
          <template slot-scope="scope">
            <el-button type="primary" plain size="small" @click="Detail(scope.row)">查看详情</el-button>
          </template>
          <!-- <el-button type="primary" plain size="small" @click="Detail = true">查看详情</el-button> -->
        </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
  <div>
    <el-dialog title="用户详情" :visible.sync="detailData">
      <el-form :model="detail" class="detailData">
        <el-form-item label="用户性质：">
          {{ detail.vipInfo === null ? '非会员' :  '会员' }}
        </el-form-item>
        <!-- <el-form-item label="会员时间：">{{ detail.from }}</el-form-item> -->
        <el-form-item label="创建时间：">{{ formatDate(detail.createdAt) }}</el-form-item>
        <el-form-item label="更新时间：">{{ formatDate(detail.updatedAt) }}</el-form-item>
        <el-form-item label="用户来源：">{{ detail.from }}</el-form-item>
        <el-form-item label="手机号：" v-if="detail.phone">{{ detail.phone }}
          <button @click="copy(detail.phone)" class="copeButton">复制</button>
        </el-form-item>
        <el-form-item label="邮箱：" v-if="detail.email">{{ detail.email }}
          <button @click="copy(detail.email)" class="copeButton">复制</button>
        </el-form-item>
        <el-form-item label="微信：" v-if="detail.openId">{{ detail.openId }}
          <button @click="copy(detail.openId)" class="copeButton">复制</button>
        </el-form-item>
        <el-form-item label="ip：">{{ detail.ip }}</el-form-item>
        <el-form-item label="地区：">{{ detail.location }}</el-form-item>
        <div style="margin-top: 15px;" v-if="detail.vipInfo">
          <h3 style="margin-bottom: 10px;">会员信息</h3>
          <el-form-item label="办理时间：">{{ formatDate(detail.vipInfo.createdAt) }}</el-form-item>
          <el-form-item label="续费时间：">{{ formatDate(detail.vipInfo.updatedAt) }}</el-form-item>
          <el-form-item label="过期时间：">{{ formatDate(detail.vipInfo.expire_time) }}</el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailData = false">取 消</el-button>
        <el-button type="primary" @click="detailData = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
  <!-- 分页 -->
  <!-- <el-pagination
    @current-change="handlePageChange"
    :current-page="page"
    :page-size="limit"
    layout="total, prev, pager, next"
    :total="total">
  </el-pagination> -->
</div>
</template>

<script>
import { overview,updateRating,updateRatingsAll,wx110,query,getDlink,updateDlink, getToken } from '@/api/admin'
import dayjs from 'dayjs'; 
import moment from 'moment';
import ClipboardJS from "clipboard";
import { mapState, mapActions } from 'vuex';
import copyText from 'copy-to-clipboard';





export default {
  name: 'Home',
  data() {
    return {
      url:'', // 搜索参数
      getList:[],
      autoGetRating:"", // 自动评级获取
      rating:'', // 评级
      total: 0,
      page:1,
      // limit:parseInt(localStorage.getItem('limit')) || 0,
      limit:20,
      views:'',
      create_start_time:'',
      create_end_time:'',
      radio1: '全部',
      batch:false,
      batchnum: 0,
      onlyBatchnum: true,
      dlink:'',
      cacheDlink:'',
      detailData: false,
      detail:{},
      cardNum:{
        activeCount:'',
        allAddCount:'',
        newAddCount:'',
        vipCount:''
      },
      vipVp:true,
      nowTime:dayjs()
    }
  },
  computed: {
    clickCount() {
      return this.$store.state.clickCount;
    }
  },
  watch: {
    '$route.params.batch'(newVal) {
      this.batch = newVal === 'true' || newVal === true;
    }
  },
  created() {
    console.log(this.$route.params.batch)
    this.b = this.$route.params.b === 'true' || this.$route.params.b === true;
    // this.batch = this.$route.params.batch === 'true'; ;
  },
  mounted() {
    // vlink获取概览数据
    this.getOverview()
    // 查询
    // this.toQuery()
    // 获取dlink
    getDlink().then(res=>{
      console.log(res)
      this.dlink = res.data.dlink
      this.cacheDlink = res.data.cacheDlink
    })
    console.log(this.$route.params.batch)
  },
  methods: {
    ...mapActions(['toggleSidebar']),
    changeMenu(){
      this.toggleSidebar()
    },
    // 复制
    copy(e){
      copyText(e)
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    // vlink获取概览数据
    // getOverview(){
    //   overview().then(res=>{
    //     console.log(res.data)
    //     this.getList = res.data.active
    //     this.cardNum.activeCount = res.data.activeCount
    //     this.cardNum.allAddCount = res.data.allAddCount
    //     this.cardNum.newAddCount = res.data.newAddCount
    //     this.cardNum.vipCount = res.data.vipCount
    //   })
    // },
    // vipData(){
    //   overview().then(res=>{
    //     this.getList = res.data.vip
    //     this.rating = true
    //     this.vipVp = false
    //   })
    // },
    // 查询
    // toQuery(){
    //   query({
    //     rating:this.rating,
    //     page:this.page,
    //     limit:this.limit,
    //     views:this.views,
    //     create_start_time:this.create_start_time,
    //     create_end_time:this.create_end_time
    //   }).then(res=>{
    //     this.getList = res.data
    //   })
    // },
    async getOverview() {
      try {
        const res = await overview();
        this.updateOverviewData(res.data);
      } catch (error) {
        console.error('Failed to fetch overview data:', error);
      }
    },
    updateOverviewData(data) {
      this.getList = data.active
      this.cardNum.activeCount = data.activeCount;
      this.cardNum.allAddCount = data.allAddCount;
      this.cardNum.newAddCount = data.newAddCount;
      this.cardNum.vipCount = data.vipCount;
    },
    async vipData() {
      try {
        const res = await overview();
        this.getList = res.data.vip;
        this.rating = true;
        this.vipVp = false;
      } catch (error) {
        console.error('Failed to fetch VIP data:', error);
      }
    },
    // 分页
    // handlePageChange(newPage) {
    //   this.page = newPage;
    //   this.toQuery();
    // },
    async toQuery() {
      if(!this.rating){
        this.rating = "0"
      }
      try {
        const res = await query({
          url: this.url,
          rating: this.rating,
          page: this.page,
          limit: this.limit,
          views: this.views,
          create_start_time: this.create_start_time,
          create_end_time: this.create_end_time
        });
        this.getList = res.data;
        this.total = res.total;
      } catch (error) {
        console.error('Failed to query data:', error);
      }
    },
    // 待审核
    ToAudit(){
      const create_start_time = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      const limit = 100
      this.url == ''
      query({
        rating:'0',
        page:this.page,
        limit:limit,
        views:'100',
        create_start_time: create_start_time,
        create_end_time: this.create_end_time
      }).then(res=>{
        this.getList = res.data;
        this.create_start_time = create_start_time;
        this.rating = '0'
        this.views = '100'
        this.limit = limit
      })
    },
    // 跳转链接
    goLink(url){
      window.open("https://vlink.cc/"+url)
    },
    // 格式化日期
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss'); // 格式化日期
    }, 
    // 会员天数
    vipDays(date){
      // console.log(dayjs('2024-08-18 17:25:27').diff(this.nowTime, 'day'))
      return dayjs(date).diff(this.nowTime, 'day')
    },
    getVipDaysClass(expireTime) {
      const days = this.vipDays(expireTime);
      return days >= 0 ? 'blue' : 'gray';
    },
    timeAgo(data) {
      data = moment(data).format("YYYY-MM-DD");
      const currentDate = moment(new Date()).format("YYYY-MM-DD"); // 获取当前日期
      const diffInDays = Math.floor(Math.abs((new Date(currentDate) - new Date(data)) / (24 * 60 * 60 * 1000))); // 计算两个日期相差的天数（不考虑小时、分钟等）
      if (diffInDays === 0) {
        return "今天";
      } else if (diffInDays === 1) {
        return "昨天";
      } else if (diffInDays > 1 && diffInDays <= 7) {
        return `${diffInDays}天前`;
      } else if (diffInDays > 7 && diffInDays <= 14) {
        return `一周前`;
      }else if (diffInDays > 14 && diffInDays <= 30) {
        return `半个月前`;
      }else if (diffInDays > 30 && diffInDays <= 60) {
        return `一个月前`;
      }else {
        return moment(data).format("YYYY年MM月DD日"); // 格式化日期输出
      }
    },
    
    //批量评级
    allDown(){
      // this.rating = newVal;
      // this.toQuery(newVal);
    },
    //单独评级
    oRating(row) {
      console.log(row.rating)
      updateRating({
        uid: row._id, //用户的_id
        rating: row.rating //评级 
      }).then(res=>{
        console.log(res)
      })
    },
    // 更新dlink
    changeDlink(){
      updateDlink({
        dlink:this.dlink
      }).then(res=>{
        console.log(res)
        this.$message({
          message: '恭喜你，这是一条成功消息',
          type: 'success'
        });
      })
    },
    Detail(row){
      console.log(row);
      this.detail = row
      this.detailData = true
    },
    goLogin(row){
      if (confirm("你确定要执行这个操作吗？")) {
        getToken({
          "url":row.url,
          "password":"111111",
          "oldPassword": row.password
        }).then(res=>{
          console.log(res.data.token)
          window.open(`https://vlink.cc/admin/make?token=${res.data.token}&isAdmin=true`)
        })
      }
    }
  },
  watch:{
    autoGetRating(newVal){
      this.rating = newVal;
      this.oldRating = newVal;
      this.toQuery(newVal);
    },
    limit(newLimit) {
      // 当 limit 数据变化时，将其保存到 localStorage
      localStorage.setItem('limit', newLimit.toString()); // 转为字符串再保存
    }
  },
}
</script>

<style lang="less" scoped>
.main{
  display: flex;
  justify-content: left;
  // overflow: hidden;
}
.home{
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  // margin-left: 20px;
  padding-top: 20px;
}
.clearfix{
  margin-top: 20px;
  display: flex;
  align-items:flex-end
}
.header-input .el-input__inner{
  width: 100px
}
.el-radio-button{
  margin-left: 10px;
}
::v-deep.el-radio-button{
  border-radius:4px;
  .el-radio-button__inner {//修改按钮样式
    width: 60px;
    border-radius: 4px !important;
    border: 1px solid rgb(212, 212, 212) !important;
  }
  // .el-radio-button__orig-radio:checked + .el-radio-button__inner {// 修改按钮激活样式
  //     color: #fff;
  //     background-color: #f63;
  //     border-color: #f63;
  //     box-shadow: -1px 0 0 0 #f63;
  // }
}
::v-deep.detailData .el-form-item{
  margin: 0;
}
.copeButton{
  color: #369fc9;
  font-size: 12px;
  background-color: #fff;
  border: 0;
  margin-left: 10px;
  cursor: pointer
}
.cardNumber{
  display: flex;
  justify-content: space-between; 
  width: 100%;
}
.card-number{
  text-align: center;
  float: left;
  width: 25%;
}
.card-num{
  font-size: 26px;
}
.blue{
  color: #1dc3fe 
}
.gray{
  color: #9e9e9e 
}
</style>